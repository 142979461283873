<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_air_details')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col
                                cols="6"
                        >
                            <v-text-field
                                    v-model="airData.airType"
                                     :label="$t('air_type')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="6"
                        >
                            <v-text-field
                                    v-model="airData.airCategory"
                                          dense
                                    outlined
                                    :label="$t('air_category')"
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="6"
                        >
                            <v-text-field
                                    v-model="airData.msn"
                                          dense
                                    outlined
                                    :label="$t('msn')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="6"
                        >
                            <v-text-field
                                    v-model="airData.flightNumber"
                                          dense
                                    outlined
                                    :label="$t('flight_number')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="6"
                        >
                            <v-text-field
                                    v-model.number="airData.planeAge"
                                    type="number"
                                          dense
                                    outlined
                                    :label="$t('plane_age')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="6"
                        >
                            <v-text-field
                                    v-model="airData.flightType"
                                     :label="$t('flight_type')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="6"
                        >
                            <v-text-field
                                    v-model.number="airData.seatNumber"
                                    :label="$t('seat_number')"
                                          dense
                                    outlined
                                    required
                                    type="number"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="6"
                        >
                            <v-text-field
                                    v-model.number="airData.freeBaggage"
                                    :label="$t('free_baggage')"
                                          dense
                                    outlined
                                    required
                                    type="number"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157,25,25)" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="formSubmit">{{$t('save')}}</v-btn>

        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "AirDetailForm",
        data() {
            return {
                airData: {},
                message: {
                    status: true,
                    text: 'true',
                }
            }
        },
        props: ['editItemIndex'],
        methods: {
            dialogueClose() {
                this.airData = {}
                this.$emit('dialogueClose')
            },
            formSubmit() {
                axios.post('Aviation/UpdateAirDetailAsync', this.airData)
                    .then(() => {
                        this.message.status = true
                        this.message.text = 'Air Detail is Successfully Updated'
                        this.$emit('formResponse', this.message)
                        this.dialogueClose()
                    })
                    .catch(() => {
                        this.message.status = false
                        this.message.text = 'Error Adding Air Detail, Please Contact Admin'
                        this.$emit('formResponse', this.message)
                        this.dialogueClose()
                    })
            },
            async getEditData() {
                const airData = await axios.get('Aviation/GetAirDetailByIDAsync/' + this.editItemIndex)
                console.log('popopopopo', airData.data)
                this.airData = airData.data
                console.log('data:', this.airData)
            },
        },
        mounted() {
            this.getEditData()
        }
    }
</script>

<style scoped>

</style>